import React from 'react'
import ContactBtn from './ContactBtn'

export default function AI() {
  return (
    <div>     
        <div className="min-height">
            <div className="background-att-ai-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Artificial Intelligence
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="w-44p">
                        <img src="./assests/image/ai-tailes.jpg" alt="" className="w-100p h-230px"/>
                    </div>
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Understanding AI </h2>
                        <p>
                            Several organizations that wasted time and money pursuing the wrong technology for the job at hand. But if they're armed with a good understanding
                                of the different technologies, companies are better positioned to determine which might best address specific needs, which vendors to work with,
                                and how quickly a system can be implemented. Acquiring this understanding requires ongoing research and education, usually within IT or an innovation group
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Actionable Data Insights </h2>
                        <p>
                            Using Machine Learning algorithms to detect patterns in vast volumes of data and interpret their meaning.
                                These are usually much more data-intensive and detailed, the models typically are trained on some part of 
                                the data set, and the models get better, that is, their ability to use new data to make predictions or put things into categories improves over time.
                        </p>
                        <p>
                            Versions of machine learning, that is, deep learning, in particular, which attempts to mimic the activity in the human brain in order
                            to recognize patterns, can perform feats such as recognizing images and speech. 
                        </p>
                    </div>
                    <div className="w-44p">
                        <img src="./assests/image/reco-ai.jpg" alt="" className="w-100p h-230px"/>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="box-container"> 
                <div className="card">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-50p">
                            <h2 className="card-content-header">How do we help?</h2>
                            <div className="card-content-para">
                                <p>
                                    With our AI Advisory, Experimentation and Engineering Services, working closely with your domain experts,
                                        we help you prioritize use cases to unlock true business value.
                                </p>
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                        <div className="w-50p">
                            <img src="./assests/image/we-help.jpg" alt="" className="w-100p"/>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
