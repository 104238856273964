import React from 'react'
import ContactBtn from './ContactBtn'

export default function Healthcare() {
  return (
    <div>          
        <div className="min-height">
            <div className="background-att-abstract-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Healthcare
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Digital Trends </h2>
                        <p>
                            Technology is changing every industry in significant ways. No one can dispute technology's ability to enable us all to live longer, healthier lives. 
                            From surgical robots to “smart hospitals,” the digital transformation is revolutionizing patient care in new and exciting ways. That's not all. It's predicted that the digital revolution can save $300 billion in spending in the sector, especially in the area of chronic diseases.
                                Clearly, there is value—human and financial—in bringing new technology to the health care market.
                        </p>
                    </div>
                    <div className="w-44p">
                        <img src="./assests/image/healthcare.jpg" alt="" className="w-100p h-300px"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Telemedicine</h2>
                        <p>
                            Even back in 2015, 80% of doctors surveyed said telemedicine is a better way to manage chronic diseases than the traditional office visit. Why? Telemedicine offers patients and health care providers both
                                a new wave of freedom and accessibility. For the first time, a patient's care options are not limited by geographic location. Even patients in remote areas can receive the highest quality of care,
                                providing they have an internet connection and smartphone. Telemedicine can also save both time and money.
                            Patients no longer have to schedule their days around routine follow-up visits (and long office waits). Instead, they can hop on a conference call to get the prescription update or check-up they need.
                        </p>
                        <p>
                            Nowhere has telepresence been more useful than in the mental health field. Now, those seeking emotional support can find access to a therapist or counsellor at the click of a button,
                            often for far less than they would pay for a full office visit. Internet therapies, for instance, “offer scalable approaches whereby large numbers of people can receive treatment and/or prevention, potentially bypassing barriers related to cost, location, lack of trained professionals, and stigma.” Telemedicine makes it possible.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body margin-top-0px">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Mobility And Cloud Access</h2>
                        <p>
                            Have you ever played phone tag with your doctor while waiting for important test results? It's so nerve-racking! That's why mobility and cloud access have been such a tremendous help
                            in increasing accessibility for patients and doctors alike. By 2021, it's estimated that 65% of interactions with health care facilities will occur by mobile devices. Some 80% of
                            doctors already use smartphones and medical apps, with 72% accessing drug info on smart phones on a regular basis. Gone are the days of paper charts and file rooms. Hospitals, insurance companies, 
                            and doctor's offices are now storing patient medical records in the cloud, with patients able to access test results online 24/7.
                        </p>
                        <p>
                            Given HIPAA laws relating to patient privacy, it's probably no surprise this has also led to an increased focus on data protection and security. According to one report, “the black-market value of
                                medical data is greater than even that of financial information.” Believe me when I say: No industry is more focused on virtualization security right now than health care.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body margin-top-0px">                        
                    <div className="w-50p">
                        <img src="./assests/image/ai-face.jpg" alt="" className="w-100p "/>
                    </div>
                    <div className="card-content w-50p">
                        <h2 className="card-content-header">Artificial Intelligence and Big Data</h2>
                        <p>
                            Big data is king in the digital world, and health care is no exception. Yes, it can be
                                gathered to measure customer satisfaction. But perhaps more importantly, it can be used to 
                                automatically identify risk factors and recommend preventative treatment. Even more exciting:
                                with the rise of the Internet of (Medical) Things (IoMT), mobile and wearable devices are increasingly 
                                connected, working together to create a cohesive medical report accessible anywhere by your health care provider.
                        </p>
                        <p>
                            This data is not just useful for the patient. It can be pooled and studied en masse to predict 
                            health care trends for entire cultures and countries.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section>
                <div className="card">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-100p">
                            <h2 className="card-content-header">Wearables And IoT</h2>
                            <div className="card-content-para">
                                <p>
                                    We remember the days when going into the local grocery store and getting our blood pressure read at one of those prehistoric machines seemed exciting. 
                                    Imagine a machine that helped us manage our own well-being without setting foot in a doctor's office. Now, mobile devices as small as our cell phone can
                                        perform ECGs, DIY blood tests, or serve as a thermometer, all without even leaving our house. With help from automation, patients can even be prompted to 
                                        check their weight, pulse, or oxygen levels, and enter results into mobile patient portals. Even better: They can transmit the results to our doctor in 
                                        real-time. Those details, when entered regularly, can help predict one's risk for heart disease and other illnesses, ultimately saving lives. 
                                        This is far more than cool. It's life-saving.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section>
            <div className="box-container"> 
                <div className="card justify-content-center">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-100p">
                            <div className="card-content-para">
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
