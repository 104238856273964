import React from 'react'

export default function Careers() {
  return (
    <div>
                   
        <div className="min-height">
            <div className="background-att-abstract-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Careers
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        {/* <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header"> Jobs </h2>
                        <p>
                            Currently, we do not have any Job Opportunities.                              
                        </p>
                    </div>
                </div>
            </div>
        </section> */}
    </div>
  )
}
