import React from 'react'
import ContactBtn from './ContactBtn'

export default function Automotive() {
  return (
    <div>   
        <div className="min-height">
            <div className="background-att-abstract-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Automotive Industry
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Digital Trends </h2>
                        <p>
                            By 2020, growing digitalization and advancements in technology will have increased the automotive industry investments to $82 billion. The automotive 
                            industry has learned quickly that they must meet consumer demands for a digitally enhanced experience when they are researching, purchasing and operating a car.
                                These are the trends currently transforming the automotive industry.
                        </p>
                    </div>
                    <div className="w-44p">
                        <img src="./assests/image/automobile.jpg" className="w-100p h-300px"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Digital Sources for Buying</h2>
                        <p>
                            Car buying used to be a source of stress, with the pervasive salesman stereotype proving true intense back and 
                            forth negotiations. Consumers, equipped only with a newspaper ad, felt ill-informed and pressured when buying a new vehicle.
                                Today, consumers are researching a car online before even stepping foot in a dealership. Actual showrooms are slowly being 
                                transformed into digital market places equipped with virtual reality capabilities. A great example of this is the Audi virtual 
                                showrooms in London where only a handful of actual cars are on display, dwarfed by the giant video screens displaying relevant buying information.
                                Virtual reality capabilities allow consumers to open doors, sneak a 360-degree peek inside and out, and even hear authentic sound effects of their
                                potential new model. In response to consumer-driven expectations to research and buy online, 
                            companies are likely to begin selling cars and parts directly through their respective websites.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Connected Supply Chain</h2>
                        <p>
                            As we've seen in other industries, digital transformation trends are being 
                            used to personalize a consumer's experience and customize to his needs. The automotive industry is no different. By digitizing the connected supply chain,
                                it drives costs down, more effectively engages the consumer, and collects and uses data to better serve consumers. Recently, the industry has moved away 
                                from the national and regional production and toward a more decentralized approach for both production and the supply chain. In addition to lower costs, 
                                this has allowed for more transparency in the supply chain, accelerating design, manufacturing, and delivery. Communication made possible by social media 
                                and the Internet of Things provides instant feedback that “talks” to a smart factory. A smart factory uses advanced algorithms and AI to perform tasks like 
                                creating schedules and managing workflow, and has robots working alongside humans on assembly lines. These connected technologies increase productivity and 
                                decrease defect rates.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section>
                <div className="card">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-50p">
                            <h2 className="card-content-header">Predictive Maintenance</h2>
                            <div className="card-content-para">
                                <p>
                                    Do your tires need air? Does a part need to be replaced? There's no more guesswork in car maintenance since 
                                    today's vehicles let us know when they need maintenance or replacement. Using advanced self-diagnostic systems, 
                                    drivers are instantly alerted any time there is a problem, allowing us to proactively service our vehicles, 
                                    drastically reducing mechanical failures and recalls. A fantastic example of predictive maintenance at work is 
                                    Michelin's tire monitoring program. “Using telematics and predictive analysis, the service provides over-the-road tire monitoring.
                                        Data is collected digitally and fed into the fleet's asset monitoring system, giving companies real-time performance analysis
                                    and wear data of specific tires on individual trucks.” Now we know there's a problem even before there's a problem!
                                </p>
                            </div>
                        </div>
                        <div className="w-50p">
                            <img src="./assests/image/bar-graph.jpg" className="w-100p"/>
                        </div>
                    </div>
                </div>
                
                <div className="card">
                    <div className="card-body margin-top-0px">
                        <div className="card-content w-100p">
                            <div className="card-content-para">
                                <p>
                                    The data maintains a connection between OEMs and customers, even as owners of one vehicle change. As far as service of a vehicle goes, that, too, has become digitized which requires not just mechanical attention and repair, but a tech-savvy technician to maintain and update software. The dynamic duo of mechanics and technology means optimal vehicular performance for longer periods of time. Remote service becomes a possibility,
                                    whereas now, owners take their cars to garages or shops. With the continued integration of technology, gauges and knobs will be replaced with a digital interface that allows for over-the-air updates, meaning owners don't have to leave their driveways for routine car maintenance.
                                </p>
                                <p>
                                    This predictive capability isn't just limited to cars and personal vehicles either, the trucking industry is already seeing a similar movement powered by sensor technologies and IoT that allows companies to monitor data proactively to ensure truck safety, fuel optimization and even monitoring of cargo.
                                    SAS, one of the leaders in this type of analytics solution has already deployed this type of solution and seen strong results that included improvements of more than 30% in truck uptime and the prediction of failures 30 days out at an accuracy rate of 90%.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section>
            <div className="box-container"> 
                <div className="card justify-content-center">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-100p">
                            <div className="card-content-para">
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
