export default function Footer() {
  return (<div>
         
         <div className="footer-container">           
                    <footer className="footer">
                        <div className="footer-col margin-left-10px">
                            <div className="nav-logo">
                                <img src="./assests/image/logo.png" alt="Shauri Technologies"/>
                                {/* <!-- ShauriTech --> */}
                            </div>
                            <p className="margin-top-20px item">
                                Shauri Technologies Ltd. has come a long way in it's Digital Journey,
                            since it founded in the year 2021.
                            </p>
                            <p className="margin-top-20px item">
                                Backed by incredible technology know-how, with a passion to un-complicate problems, 
                                throw light and show a clear path for solutions, we're dedicated to giving you the very best.
                            </p>
                            
                            <a href="about" className="link-btn margin-top-20px margin-10px" role="button">
                                <span className="view-more">
                                    <span>View More </span>
                                    <i aria-hidden="true" className="fa fa-arrow-right"></i>
                                </span>
                            </a>
                            <div className="margin-top-20px">
                                <span className="social-media-icons">
                                    <i className="fa fa-facebook-f" style={{color: '#3b5998'}}></i>
                                </span>
                                <span className="social-media-icons">
                                    <i className="fa fa-twitter" style={{color: '#1da1f2'}}></i>
                                </span>
                                <span className="social-media-icons">
                                    <i className="fa fa-google-plus" style={{color: "#b94536"}} aria-hidden="true"></i>
                                </span>
                                <span className="social-media-icons">
                                    <i className="fa fa-linkedin" style={{color: "#0077b5"}} aria-hidden="true"></i>
                                </span>
                            </div>

                        </div>
                        <div className="footer-col">
                            <div className="header">Contact</div>
                            
                            <div className="margin-top-20px item">
                                116, Altmore Avenue, E6 2BU, London, United Kingdom
                            </div>
                            <a href="contact" className="link-btn margin-10px" role="button">
                                <span className="view-more">
                                    <span>View More </span>
                                    <i aria-hidden="true" className="fa fa-arrow-right"></i>
                                </span>
                            </a>
                            <div className="margin-top-20px item">
                                <span className="number">+44 739 318 3111</span>
                                <div>contact@shauritechnologies.com</div>
                            </div>
                        </div>
                        <div className="footer-col">
                           <div  className="header"> Links</div>
                            <div className="margin-top-20px">
                                <a href="home" className="link-btn"> Home</a>
                                <a href="careers" className="link-btn"> Careers</a>
                                <a href="digital-transformation" className="link-btn"> Digital Transformation</a>
                                <a href="ai" className="link-btn"> Artificial Intelligence</a>
                                <a href="cloud-services" className="link-btn"> Cloud Services</a>
                                <a href="iot" className="link-btn"> IoT</a>
                            </div>
                        </div>
                        <div className="footer-col">
                         <div  className="header"> Industries</div>
                            <div className="margin-top-20px">
                                
                                <div className="footer-img-container">
                                    <img src="./assests/image/automobile.jpg" alt="Avatar" className="image" style={{width: '100%'}} />
                                    <div className="middle">
                                    <div className="text"><a href="automotive">Automotive Industry</a></div>
                                    </div>
                                </div>
                                <div className="footer-img-container">                                    
                                    <img src="./assests/image/money-2724241_1280.jpg" alt="Avatar" className="image" style={{width: '100%'}} />
                                    <div className="middle">
                                    <div className="text"><a href="banking">Banking and Finance</a></div>
                                    </div>
                                </div>
                                <div className="footer-img-container">                                    
                                    <img src="./assests/image/healthcare.jpg" alt="Avatar" className="image" style={{width: '100%'}} />
                                    <div className="middle">
                                    <div className="text"><a href="healthcare">Healthcare</a></div>
                                    </div>
                                </div>
                                <div className="footer-img-container">                                
                                    <img src="./assests/image/media.jpg" alt="Avatar" className="image" style={{width: '100%'}} />
                                    <div className="middle">
                                    <div className="text"><a href="media">Media and Entertainment</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <p className="page-margin rights  pad-BT-20px">
                    ©2022. Shauri Technologies Limited.,
                    <br />
                    All Rights Reserved.
                    </p>
                </div>
  </div>);
}
