import React from 'react'
import ContactBtn from './ContactBtn'

export default function Media() {
  return (
    <div>
                   
        <div className="min-height">
            <div className="background-att-abstract-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Media and Entertainment
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Digital Trends </h2>
                        <p>
                            Whether you're a movie fan, a video game junkie, or a TV binge-watcher, you know that new technologies have completely changed the way we consume, connect, and communicate. It's no wonder the entertainment industry has been turned on its head recently due to technology. From the types of content being created, to where the content is being consumed,
                                and who is now creating it— “Hollywood” has become a virtual Wild West, and the changes are still coming
                        </p>
                    </div>
                    <div className="w-44p">
                        <img src="./assests/image/media.jpg" alt="" className="w-100p h-300px"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Multi-Channel Experiences</h2>
                        <p>
                            Research shows people watch clips and user-generated content on their phones, and movies on their computers
                                and TVs—sometimes simultaneously. Media companies have learned that they need to take advantage of that 
                                situation by prompting viewers to #share using branded content and hashtags to gain their content even 
                                greater reach. This means marketers and writers are no longer thinking solely about what would make a 
                                great scene. They're thinking what would look great posted on FB, what would look cool as a GIF, and how
                                to engage their viewers while they are watching.                                
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body margin-top-0px">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Virtual And Mixed Reality</h2>
                        <p>
                            While the trend is still in its infancy compared to where it will go, the success of virtual and mixed reality 
                            is starting to make its way into media and entertainment. Last summer, we saw the European Soccer Championships 
                            featured in 360 VR immersing fans into the middle of live games giving them a taste of the power of virtual 
                            reality. Of course, we also saw the Pokemon Go craze come and go over the past 18 months and while this was 
                            just a tiny introduction to the 
                            capabilities of mixed reality, it certainly showed just how interested people are in blended experiences.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="card">
                <div className="card-body margin-top-0px">                        
                    <div className="w-44p">
                        <img src="./assests/image/ai-face.jpg" alt="" className="w-100p "/>
                    </div>
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Artificial Intelligence Is Getting Creative</h2>
                        <p>
                            In 2015, spending for AI was $300-350 million. Its projection for 2025: $30 billion. What gives? At risk of sounding crazy, 
                            AI is changing the entire creative process. In a new wave of computer-human “collaboration,” companies are already 
                            using AI to create effective movie plot points based on box office performance, and one company recently used AI 
                            to create a horror movie trailer based on trailers the AI had already processed and annexed. The result? The 
                            AI created the final trailer within 24 hours—far less than the usual 30 days it would take for a manual edit. 
                            And it used clips and scenes proven to be effective by market research, increasing the likelihood that it would
                                actually get viewers to watch.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="card">
                <div className="card-body margin-top-0px">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Advertising Is Getting Smarter</h2>
                        <p>
                            Because of deep analytics possible with machine learning, as well as the vast opportunities available with 
                            augmented reality and AI, advertising is even smarter than ever. Forget traditional product placements. 
                            Soon it will be possible to order your favourite actor's jacket just by clicking the screen when it appears.
                                Even more, using big data, marketing agencies will even be able to switch out specific product 
                            placements to use ones you're most likely to buy. If that isn't entertaining, I don't know what is.
                        </p>
                        <p>
                            Unlike healthcare, the digital transformation happening in the media and entertainment business focuses mostly on—you guessed 
                            it—marketing and money. But they also focus on user experience and engagement: personalizing one's viewing experiencing,
                                and getting content to audiences where and when they want it, on whatever device they're using.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="box-container"> 
                <div className="card justify-content-center">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-100p">
                            <div className="card-content-para">
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
