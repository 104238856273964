import React from 'react';
import ContactBtn from './ContactBtn';

export default function Home() {
  return (<div>
      
      <div>
            <div className="videoContainer">                
                <div className="overlay-content">
                    <div>
                        <h2 className="color-A19797 video-title margin-10px">Enterprise Solutions</h2>
                        <hr className="divider" />
                        <h2 className="video-sub-title margin-10px"> From Idea to Business</h2>
                    </div>
                </div>                
                <div className="overlay">
                </div>
                
                <div className="w-100p">
                <img src="./assests/image/abstract-home-banner.jpg" alt="" className="w-100p height-bg-img-560px"/>
            </div>
                {/* <video className="video background-video" autoplay="" muted="" playsinline="" loop="" src="./assests/image/coffee-1.mp4" ></video> */}
            </div>
            <div className="margin-top-20px">
                <section>
                    <div className="min-height">
                        <div className="background-att-computer-img height-bg-img"></div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="w-44p">
                                <img src="./assests/image/image_Processing.jpg" alt="" className="w-100p h-230px"/>
                            </div>
                            <div className="card-content w-54p">
                                <h2 className="card-content-header text-align-center border-radius-5px pad-10px linear-bg-grad">Digital Transformation </h2>
                                <p>
                                    Leave it with us, we will transform your legacy systems to match current technologies. 
                                    Growing in business is possible ONLY through delivering value to customers.
                                    To improve your products and services, it is high time to have the right data, 
                                    infrastructure, tools, applications, and process frameworks in place.
                                    The “why” of your organization's digital transformation might be around
                                    improving customer experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Artificial Intelligence --> */}
                <section>
                    <div className="min-height">
                        <div className="background-att-ai-img height-bg-img"></div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-content w-54p">
                                <h2 className="card-content-header text-align-center border-radius-5px pad-10px linear-bg-grad">Artificial Intelligence</h2>
                                <p>
                                    With our AI Engineering Services, we enable you to implement AI more productively,
                                     efficiently and economically. Several organizations that wasted time and money pursuing 
                                     the wrong technology for the job at hand. But if they're armed with a good understanding of 
                                     the different technologies, companies are better positioned to determine which might best 
                                     address specific needs, which vendors to work with, and how quickly a system can be implemented.
                                      Acquiring this understanding requires ongoing research and education, usually within IT or an 
                                      innovation group.
                                </p>
                            </div>
                            <div className="w-44p">
                                <img src="./assests/image/artificial-intelligence.jpeg" alt="" className="w-100p"/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Cloud Services --> */}
                <section>
                    <div className="min-height">
                        <div className="background-att-server-img height-bg-img"></div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="w-44p">
                                <img src="./assests/image/cloud.jpg" alt="" className="w-100p h-230px"/>
                            </div>
                            <div className="card-content w-54p">
                                <h2 className="card-content-header text-align-center border-radius-5px pad-10px linear-bg-grad">Cloud Services</h2>
                                <p>
                                    Organizations are heading for fast adoption of Cloud Technologies – Cloud Migration, 
                                    Cloud Computing, Cloud Data Analytics and DevOps. Our Cloud Engineering experts work 
                                    hand in hand with you, to identify which applications or databases should migrate,
                                     which cloud computing strategy may best work for you, in which area you should focus 
                                     on data analytics, how seamlessly you can integrate DevOps for your projects. AWS or 
                                     Azure or Google Cloud, whatever may be your preference, we are here to help.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Internet of Things (IoT) --> */}
                <section>
                    <div className="min-height">
                        <div className="background-att-iot-img height-bg-img">
                            <div className='blur' ></div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-content w-54p">
                                <h2 className="card-content-header text-align-center border-radius-5px pad-10px linear-bg-grad">Internet of Things (IoT)</h2>
                                <p>
                                    IoT devices record and transfer data to monitor important processes, 
                                    give us new insights, boost efficiency, and allow companies to make more 
                                    informed decisions. They tell organisations what's really happening, 
                                    rather than what they assume or hope is happening. And the reams of data 
                                    they gather are grist to the mill for analytics and AI systems, which can identify 
                                    patterns of use or behaviour that were previously hidden.
                                </p>
                            </div>
                            <div className="w-44p">
                                <img src="./assests/image/iot.jpg" alt="" className="w-100p"/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- WHY US -->                 */}
                <section>
                    
                    <div className="min-height">
                         <div className="background-att-why-us-img height-bg-img-620px">
                            <div className="why-card">
                                <div className="why-card-header margin-top-20px margin-btn-15px">
                                    WHY US?
                                </div>
                                <div className="why-card-content">
                                    <p className="margin-btn-40px">We look at ourselves, from your PERSPECTIVE!</p>
                                    <div className="progress-bar margin-top-20px">
                                        <div className="meter-header">EXTREMELY UNDERSTANDABLE</div>                                        
                                        <div className="meter">
                                            <span style={{width: '100%'}}><span className="progress"></span></span>
                                        </div>
                                    </div>
                                    <div className="progress-bar margin-top-20px">
                                        <div className="meter-header">EXPERTS IN CUTTING EDGE TECHNOLOGIES</div>                                        
                                        <div className="meter">
                                            <span style={{width: '100%'}}><span className="progress bg-green"></span></span>
                                        </div>
                                    </div>
                                    <div className="progress-bar margin-top-20px">
                                        <div className="meter-header">PIXEL PERFECT PERFECTION</div>                                        
                                        <div className="meter">
                                            <span style={{width: '100%'}}><span className="progress bg-363234"></span></span>
                                        </div>
                                    </div>
                                    <div className="progress-bar margin-top-20px">
                                        <div className="meter-header">COMMITMENT</div>                                        
                                        <div className="meter">
                                            <span style={{width: '100%'}}><span className="progress bg-3a45f2"></span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-wrapper ">
                                    <ContactBtn />
                                </div>
                            </div>
                         </div>
                    </div>
                </section>
                {/* <section>
                    <div className="margin-top-20px pad-top-20px heading-title">
                        Our testimonials
                    </div>
                    <div>
                        <div className="testimonials">
                            <div className="testimonial-card">
                                <div className="commentor-content">
                                    <p>Great work, you made us realize the true value of our legacy data, the data insights you brought to the table, and the way you presented in a dashboard, is quite amazing</p>
                                    <span className="border-hr"></span>
                                    <span className="profile-info">
                                        <strong className="author-name">David </strong>
                                        <span className="author-desc">Director, XYZ</span>
                                    </span>
                                </div>
                            </div>
                            <div className="testimonial-card">
                                <div className="commentor-content">
                                    <p>When it comes to digital transformation, there are loads of complexities that take a good amount of time, but your team made it so simple and helped us to prioritize and get things done smoothly</p>
                                    <span className="border-hr"></span>
                                    <span className="profile-info">
                                        <strong className="author-name">John will</strong>
                                        <span className="author-desc">Director, xyz</span>
                                    </span>
                                </div>
                            </div>
                            <div className="testimonial-card">
                                <div className="commentor-content">
                                    <p>We really appreciate the way you integrated our IoT data into our Cloud applications and your power-saving designs. Your work is commendable and we strongly recommend your services</p>
                                    <span className="border-hr"></span>
                                    <span className="profile-info">
                                        <strong className="author-name">Will Garcia</strong>
                                        <span className="author-desc">Director, XYZ</span>
                                    </span>
                                </div>
                            </div>
                            <div className="testimonial-card">
                                <div className="commentor-content">
                                    <p>We really appreciate the way you integrated our IoT data into our Cloud applications and your power-saving designs. Your work is commendable and we strongly recommend your services</p>
                                    <span className="border-hr"></span>
                                    <span className="profile-info">
                                        <strong className="author-name">Garcia Well</strong>
                                        <span className="author-desc">Director, XYZ</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        </div>
  </div>);
}
