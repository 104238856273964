export const Header = () =>  {
    
function navBarFun() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
        x.className += " responsive";     
    } else {
      x.className = "topnav";
    }
}

  return (
    <div className="topnav-wrapper">  
        <div className="topnav" id="myTopnav">
            <div className="nav-logo">
                <img src="./assests/image/logo.png" alt="Shauri Technologies"/>
            </div>
            <div className="nav-item">
                <a href="/home" className="active">Home</a>
                <a href="/about">About Us</a>
                <div className="dropdown">
                    <button className="dropbtn">Services 
                        <i className="fa fa-chevron-down"></i>
                    </button>
                    <div className="dropdown-content">
                        <a href="digital-transformation">Digital Transformation</a>
                        <a href="ai">Artificial Intelligence</a>
                        <a href="cloud-services">Cloud Services</a>
                        <a href="iot">IoT</a>
                    </div>
                </div> 
                <div className="dropdown">
                    <button className="dropbtn">Industries 
                        <i className="fa fa-chevron-down"></i>
                    </button>
                    <div className="dropdown-content">
                        <a href="automotive">Automotive Industry</a>
                        <a href="banking">Banking and Finance</a>
                        <a href="healthcare">Healthcare</a>
                        <a href="media">Media and Entertainment</a>
                    </div>
                </div> 
                <a href="careers">Careers</a>
                <a href="contact">Contact Us</a>
            </div>
            <a style={{'font-size':'15px'}} className="icon" onClick={navBarFun}> &#9776; </a>
        </div>
    </div>

    
 );
}

export default Header;