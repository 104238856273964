import ContactBtn from "./ContactBtn";

export const About = () =>  { 
    return (
        <>             
        <div className="min-height">
            <div className="background-att-bulb-img height-bg-img-560px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header margin-top-20px margin-btn-15px">
                        About Us
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        
        <section>
                <div className="card slideanim">
                    <div className="card-body">
                        <div className="card-content w-50p">
                            <h2 className="card-content-header">Who We Are?</h2>
                            <div className="card-content-para">
                                <p>
                                    Shauri Technologies Ltd. has come a long way in it's Digital Journey, 
                                    since it founded in the year 2021.
                                </p>
                                <p>
                                    Backed by incredible technology know-how, with a passion to un-complicate problems, 
                                    throw light and show a clear path for solutions, we're dedicated to giving you the very best.   
                                </p>
                                <p>
                                    We hope you enjoy our services as much as we enjoy offering them to you. If you 
                                    have any questions or comments, please don't hesitate to contact us. 
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                        <div className="w-50p" >
                            <img src="./assests/image/manipulation-smartphone.jpg" className="w-100p"/>
                        </div>
                    </div>
                </div>
        </section>
        
        <section>
                <div className="box-container">           
                    <div className="box">
                        <div className="box-col margin-left-10px">
                            <h3>
                                <span> Philosophy </span>
                            </h3>
                            <p>
                                There is no such problem exists, that doesn't have a solution, we just need to find it, that's all!
                                <br />
                                Trouble yourself in stretching your boundaries, then for sure, you can eradicate Client's troubles and simplify things for them.
                            </p>
                        </div>
                        <div className="box-col margin-left-10px">
                            <h3>
                                <span> Vision </span>
                            </h3>
                            <p>
                                To become a leading Company, in resolving Digital Challenges, 
                                with Cutting Edge Technologies.
                            </p>
                        </div>
                        <div className="box-col margin-left-10px">
                            <h3>
                                <span> Values </span>
                            </h3>
                            <p >
                                Our Core Values are:<br />
                                <br />Empathy<br />

                                Leadership<br />
                                
                                Passion<br />
                                
                                Respect</p>
                        </div>
                    </div>
                    </div>
            </section>
        </>
    );
}   
export default About;