import React from 'react'
import ContactBtn from './ContactBtn'

export default function CloudServices() {
  return (
    <div>
        <div className="min-height">
            <div className="background-att-cloud-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header margin-top-20px margin-btn-15px">
                        Cloud Services
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Cloud Computing </h2>
                        <p>
                            <b>Infrastructure as a Service</b>,
                                or IaaS, gives business access to vital web architecture, such as storage space, servers, and connections, without the business need of purchasing and managing this 
                            internet infrastructure themselves. Because of the economies of scale and specialization involved, this can be to the benefit of both the business providing the infrastructure and the one using it.
                                In particular, IaaS allows an internet business a way to develop and grow on demand. Both PaaS and SaaS clouds are grounded in IaaS clouds, as the company providing the software as service is also providing the infrastructure to run the software. Choosing to use an IaaS cloud demands a willingness to put up with complexity, but with that complexity comes flexibility.
                        </p>
                        <p>
                            <b> Platform as a Service (PaaS) </b> 
                            clouds are created, many times inside IaaS Clouds by specialists to render the scalability and deployment of any application trivial and to help make your expenses scalable and predictable. 
                            The chief benefit of a service like this is that for as little as no money you can initiate your application with no stress more than basic development and maybe a little porting if you are dealing with an existing app. Furthermore,
                                PaaS allows a lot of scalability by design because it is based on cloud computing. If you want a lean operations staff, a PaaS can be very useful if your app will capitulate.
                        </p>
                        <p>
                            <b>Software as a Service (SaaS)</b>
                            is relatively mature, and the phrase's use predates that of cloud computing. Cloud applications allow the cloud to be leveraged for software architecture, reducing the burdens of maintenance, support,
                                and operations by having the application run on computers belonging to the vendor.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="w-44p">
                        <img src="./assests/image/cloud.jpg" className="w-100p h-230px"/>
                    </div>
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Cloud Migration </h2>
                        <p>
                            Cloud migration services aid in the process of moving data, product applications, 
                            and other business management documents from on-premise servers to a cloud computing environment.
                                Cloud migration services are used to move data and applications to a more effective and secure environment than on-premise servers.
                                Migration to the cloud cut costs, free up server space and cut down on hardware maintenance
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Cloud Analytics</h2>
                        <p>
                            More and more businesses rely on data analysis to gain a competitive advantage, to advance scientific discovery,
                                or to improve life in all sorts of ways. Cloud analytics has therefore become an increasingly valuable tool as the quantity and the value of data continues to climb, it involves deployment of scalable cloud computing with powerful analytic software to identify patterns in data and to extract new insights.
                        </p>
                        <p>
                            By leveraging AI and other analytics approaches, organizations of all sizes can quickly make data-driven decisions to gain efficiencies in their products and services. The cloud is an indispensable platform that enables quick experimentation of ideas through proofs of concept (POCs) and provides a rich software
                                ecosystem for building AI applications and for training DL models.
                        </p>
                    </div>
                    <div className="w-44p">
                        <img src="./assests/image/analytics.jpg" className="w-100p h-300px"/>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="box-container"> 
                <div className="card">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-50p">
                            <h2 className="card-content-header">How do we help?</h2>
                            <div className="card-content-para">
                                <p>
                                    Our Cloud Engineering experts work hand in hand with you, to identify which applications or databases should migrate, which cloud computing strategy may best work for you, in which area you should focus on data analytics, how seamlessly you can integrate DevOps for your projects.
                                    AWS or Azure or Google Cloud, whatever may be your preference, we are here to help.
                                </p>
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                        <div className="w-50p">
                            <img src="./assests/image/we-help.jpg" className="w-100p"/>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
