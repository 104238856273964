import React from 'react'

export default function ContactBtn() {
  return (
    <div>        
        <a href="contact" className="btn btn-2" role="button">
            <span>
                <i aria-hidden="true" className="fa fa-address-card-o"></i>
                <span className=''> Get in touch</span>
            </span>
        </a>
    </div>
  )
}
