import React from 'react'
import ContactBtn from './ContactBtn'

export default function IOT() {
  return (
    <div>     
        <div className="min-height">
            <div className="background-att-iot-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header margin-top-20px margin-btn-15px">
                        IoT
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="w-44p">
                        <img src="./assests/image/iot.jpg" alt="" className="w-100p h-230px"/>
                    </div>
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">IOT for Business</h2>
                        <p>
                            IoT devices record and transfer data to monitor important processes, 
                            give us new insights, boost efficiency, and allow companies to make more informed decisions.
                        </p>
                        <p>
                            They tell organisations what's really happening, rather than what they assume or hope is happening. 
                            And the reams of data they gather are grist to the mill for analytics and AI systems, which can identify 
                            patterns of use or behaviour that were previously hidden.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Asset Tracking</h2>
                        <p>
                            Closely linked to efficiency and productivity is the drive to reduce waste, to which IoT tracking is integral.

                        </p>
                        <p>
                            The Asset Tracking solution is a complete sensing solution for facilities managers looking to track and monitor key assets around their facilities – Tracking of portable devices
                            , tools, staff, vehicles, and equipment in hospitals, offices, construction sites and industrial plants, and uses geofencing and alerts for specific assets.
                        </p>
                    </div>
                    <div className="w-44p">
                        <img src="./assests/image/asset-tracking-system.png" alt="" className="w-100p h-300px"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="row">
                <div className="col">
                    <div className="testimonials margin-0px">
                        <div className="testimonial-card-info plans-card">
                            <div className="commentor-content">
                                <span className="profile-info">
                                    <strong className="author-name">Occupancy </strong>
                                </span>
                                <span className="border-hr"></span>
                                <p>
                                    The Desk/Room Occupancy Monitoring solution is a complete sensing service for facilities managers 
                                    looking to better manage their desk space availability. Monitor and track desk occupancy in real-time, obtain real-time alerts to better 
                                    analyse data to improve efficiency and office energy consumption.    
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="testimonials margin-0px">
                        <div className="testimonial-card-info plans-card">
                            <div className="commentor-content">
                                <span className="profile-info">
                                    <strong className="author-name">Indoor-Air Quality </strong>
                                </span>
                                <span className="border-hr"></span>
                                <p>
                                    Indoor Air Quality Monitoring is a fundamental tenet of building wellness. This solution brings cost savings, 
                                    sustainability and occupant comfort with real-time data on temperature, humidity and CO2 levels. Studies show 
                                    that more granular control of temperatures and proper monitoring of CO2 levels reduces the stress level of your workforce, 
                                    customers and tenants, raising productivity and satisfaction levels.    
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col">
                    <div className="testimonials margin-0px">
                        <div className="testimonial-card-info plans-card">
                            <div className="commentor-content">
                                <span className="profile-info">
                                    <strong className="author-name">Predictive Cleaning </strong>
                                </span>
                                <span className="border-hr"></span>
                                <p>
                                    The Predictive Cleaning solution is a complete sensing service for those looking to reduce infection risk, improve efficiencies, cleanliness and increase user satisfaction. Typical use cases include 
                                    Toilet/washroom cleaning monitoring, cleaning contractor & client SLA management, user feedback monitoring and real-time incident response [leaks, spills & empty dispensers, for example].   
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col">
                    <div className="testimonials margin-0px">
                        <div className="testimonial-card-info plans-card">
                            <div className="commentor-content">
                                <span className="profile-info">
                                    <strong className="author-name">Leak Detection </strong>
                                </span>
                                <span className="border-hr"></span>
                                <p>
                                    The Leak Detection solution is a complete sensing service for facilities managers looking to 
                                    better monitor their facilities and storage areas for indications of water leaks. Use cases:
                                        Monitor and track for leaks in specific areas around your facilities. Used in offices, hospitals, storage facilities and industrial units.
                                        This solution requires custom development work tailored for specific client use cases.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="box-container"> 
                <div className="card">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-50p">
                            <h2 className="card-content-header">How do we help?</h2>
                            <div className="card-content-para">
                                <p>
                                    With our IoT Engineering Services, working closely with your 
                                    domain experts, we help you prioritize use cases to unlock true business value.
                                </p>
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                        <div className="w-50p">
                            <img src="./assests/image/we-help.jpg" alt="" className="w-100p"/>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
