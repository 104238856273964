import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import DigitalTransformation from './components/DigitalTransformation'
import Footer from './components/Footer';
import AI from './components/AI';
import IOT from './components/IOT';
import Media from './components/Media';
import Automotive from './components/Automotive';
import CloudServices from './components/CloudServices';
import Careers from './components/Careers';
import Banking from './components/Banking';
import Healthcare from './components/Healthcare';
function App() {
  return (
    <div className="App">
      <Header />
        <Router>
              <Routes>
                <Route path="/" element={ <Home />} />
                <Route path="Home" element={ <Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/careers" element={<Careers />} />

                <Route path="/digital-transformation" element={<DigitalTransformation />} />
                <Route path="/cloud-services" element={<CloudServices />} />
                <Route path="/AI" element={<AI />} />
                <Route path="/iot" element={<IOT />} />

                <Route path="/media" element={<Media />} />
                <Route path="/automotive" element={<Automotive />} />
                <Route path="/banking" element={<Banking />} />
                <Route path="/healthcare" element={<Healthcare />} />

                <Route path='*' element={<Home />} />
              </Routes>
        </Router>
      <Footer />
    </div>
  );
}

export default App;
