import React from 'react'

export default function Contact() {
  return (
    <div>         
        <div className="min-height">
            <div className="background-att-abstract-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Contact Us
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>

        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-104p">
                        <h2 className="card-content-header">Find out what we can do for you. </h2>
                        <p>
                            Whether you have a question about our services, features, pricing, need a demo, or anything else,
                            our experts are ready to answer them all. We look forward to hearing from you.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        
        <section>
            <div className="card justify-content-center bg-FFCB9A">
                <div className="card-body">
                    <form action="mailto:contact@shauritechnologies.com" method="post" enctype="multipart/form-data" >
                        <div className="row justify-content-center">
                            <span className="form-heading">
                                Contact Us form
                            </span>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input type="text" className="input-txt" placeholder="First Name *" required />
                            </div>
                            <div className="col">
                                <input type="text" className="input-txt" placeholder="Last Name" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input type="text" name="CompanyName" className="input-txt" placeholder="Company Name*" required/>
                            </div>
                            <div className="col">
                                <input type="text" name="JobTitle" className="input-txt" placeholder="Job Title*" required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input type="email" name="Email" className="input-txt" aria-required="true" aria-invalid="false" placeholder="Email*" required />
                            </div>
                            <div className="col">
                                <input type="text" name="PhoneNumber" className="input-txt" placeholder="Phone Number" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <textarea name="Message" className="w-90p" rows="10" placeholder="Message *" required></textarea>
                            </div>
                        </div>   
                        <div className="row justify-content-center">
                            <input type="submit" value="Send" className="" />
                        </div>
                    </form>
                </div>
            </div>
        </section>

        <section>
            <div className="footer">
                <div className="footer-col">
                    <div className="testimonials margin-0px">
                        <div className="testimonial-card-info plans-card height-200">
                            <div className="commentor-content">
                                <span className="profile-info">
                                    <strong className="author-name">VISIT US </strong>
                                </span>
                                <span className="border-hr"></span>
                                <p>
                                    <h2>116, Altmore Avenue, E6 2BU, London, United Kingdom</h2>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-col">
                    <div className="testimonials margin-0px">
                        <div className="testimonial-card-info plans-card height-200">
                            <div className="commentor-content">
                                <span className="profile-info">
                                    <strong className="author-name">CALL US </strong>
                                </span>
                                <span className="border-hr"></span>
                                <p>
                                    <h2>+44 739 318 3111 </h2>
                                </p>                                    
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="footer-col">
                    <div className="testimonials margin-0px">
                        <div className="testimonial-card-info plans-card height-200">
                            <div className="commentor-content">
                                <span className="profile-info">
                                    <strong className="author-name">CONTACT US</strong>
                                </span>
                                <span className="border-hr"></span>
                                <p>
                                    <h2>contact@shauritechnologies.com </h2>  
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
  )
}

