import React from 'react'
import ContactBtn from './ContactBtn'

export default function DigitalTransformation() {
  return (
    <div>                   
        <div className="min-height">
            <div className="background-att-digitization-technology-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Digital Transformation
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
            
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-50p">
                        <h2 className="card-content-header">Delivering Value</h2>
                        <div className="card-content-para color-423E3E">
                            <p>
                                Growing in business is possible ONLY through delivering value to customers.
                                To improve your products and services, it is high time to have the right data, 
                                infrastructure, tools, applications, and process frameworks in place. 
                                The “why” of your organization's digital transformation might be around improving 
                                customer experience, reducing friction, increasing productivity, or elevating profitability.
                            </p>
                        </div>
                    </div>
                    <div className="w-50p">
                        <img src="./assests/image/delivering-values.jpg" className="w-100p"/>
                    </div>
                </div>
            </div>
        </section>

        <section> 
            <div className="card">
                <div className="card-body margin-BT-10px">
                    <div className="card-content">
                        <h2 className="card-content-header">Technology</h2>
                        <div className="card-content-para color-423E3E">
                            <p>
                                One hand, there is heavy competition, on the other, the introduction of new technologies – the Internet of Things, to the blockchain,
                                to data lakes, to artificial intelligence to cloud, the raw potential of emerging technologies is staggering. And while many of these
                                are becoming easier to use, understanding how any particular technology contributes to transformational opportunity, 
                                adapting that technology to the specific needs of the business, and integrating it with existing systems is extremely complex.
                            </p>
                            <p>
                                Complicating matters, most companies have enormous technical debt — embedded legacy technologies that are difficult to change. 
                                You can only resolve these issues with people who have technical depth and breadth, and the ability to work hand-in-hand with the business.
                            </p>
                        </div>
                    </div>
                </div>
                </div>       
        </section> 
        
        <section>
            <div className="card">
                <div className="card-body margin-BT-10px">
                    <div className="card-content w-50p">
                        <h2 className="card-content-header">Data</h2>
                        <img src="./assests/image/file.jpg" className="margin-top-10px w-100p"/>
                    </div>
                    <div className="w-50p margin-top-20px">
                        <div className="card-content-para color-423E3E">
                            <p>
                                It is quite natural that whilst business is scaling, the number of departments, people, applications, 
                                processes also will evolve, leading to data redundancy. To achieve similar goals, multiple teams were implementing
                                    different applications. While the competition is heavily demanding new transformations and speed to move forward, we have really too much of legacy burdens that are pulling us backwards.
                            </p>
                            <p>
                                The unfortunate reality is that, at many companies today, most data is not up to basic standards, 
                                and the rigours of transformation require much better data quality and analytics. Transformation almost certainly 
                                involves understanding new types of unstructured data, massive quantities of data external to your company, leveraging proprietary data, and integrating everything together, all while shedding enormous quantities of data that have never been used or will be used
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section> 
            <div className="card">
                <div className="card-body margin-BT-10px">
                    <div className="card-content">
                        <h2 className="card-content-header">Process</h2>
                        <div className="card-content-para color-423E3E">
                            <p>
                                Transformation requires an end-to-end mindset, a rethinking of ways to meet customer needs, seamless connection of work activities, and the ability to manage across 
                                silos going forward. Process orientation is a natural fit with these needs. But many have found process management — horizontally, across silos, and focused on customers 
                                — difficult to reconcile with traditional hierarchical thinking. As a result, this powerful concept has languished. Without it, transformation is reduced to a series of incremental improvements — important and helpful, but not truly transformative.
                            </p>
                        </div>
                    </div>
                </div>
                </div>       
        </section> 
        
        <section>
            <div className="box-container"> 
                <div className="card">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-50p">
                            <h2 className="card-content-header">How do we help?</h2>
                            <div className="card-content-para">
                                <p>
                                    By taking stock of your existing applications, technology foot-print, processes, understanding and analysing your current and 
                                    future requirements, we will provide a detailed step-by-step
                                        prioritised plan that will transform your legacy systems to latest cutting edge technologies, without impacting your business as usual.
                                </p>
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                        <div className="w-50p">
                            <img src="./assests/image/we-help.jpg" className="w-100p"/>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
