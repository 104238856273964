import React from 'react'
import ContactBtn from './ContactBtn'

export default function Banking() {
  return (
    <div>
        <div className="min-height">
            <div className="background-att-abstract-img height-bg-img-300px overlay-card-wrapper">
                <div className="overlay-card container-margin">
                    <div className="overlay-card-header bg-00000059 margin-top-20px margin-btn-15px">
                        Banking and Finance
                    </div>
                    <div className="overlay-card-content">
                    </div>

                </div>
            </div>
        </div>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-54p">
                        <h2 className="card-content-header">Challenges & Opportunities </h2>
                        <p>
                            Banking and Financial service firms are facing many challenges today.
                        </p>
                        <p>
                            Most of it has to do with the rapid changes in technology. While the vast majority of financial firms have embraced the technology revolution,
                            there are still many challenges these companies have to face.
                        </p>
                    </div>
                    <div className="w-44p">
                        <img src="./assests/image/money-2724241_1280.jpg" className="w-100p h-300px"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="card-content w-100p">
                        <h2 className="card-content-header">Keeping Up with Technology</h2>
                        <p>
                            Business growth is very important for financial firms, but in order to grow, they must spend money 
                            updating their technology. According to a report from Protiviti, financial service firms must continue to
                                invest in technology such as robotics and other workflow automation tools to increase their efficiency and
                                reduce the costs associated with operational, risk management and compliance.
                        </p>
                        <p>
                            Firms must also modernize their technology platforms and data storage so they can enable big data solutions
                                such as AI-supported digital customer support assistants.
                        </p>
                        <p>
                            Financial firms must also consider consolidating platforms and provide a more efficient, customer-friendly 
                            experience across internet, mobile and physical locations.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section>
                <div className="card">
                    <div className="card-body margin-BT-10px">
                        <div className="w-50p">
                            <img src="./assests/image/artificial-intelligence.jpeg" className="w-100p"/>
                        </div>
                        <div className="card-content w-50p">
                            <h2 className="card-content-header">Incorporating AI</h2>
                            <div className="card-content-para">
                                <p>
                                    Deloitte found that 30% of financial service firms who they describe as frontrunners are 
                                    more adept at utilizing AI which is helping them increase revenue faster than their competitors.
                                </p>
                                <p>
                                    60% of frontrunner firms define success by increased revenue and 47% say customer experience has improved.
                                        Frontrunners whose businesses have increased revenue, improved customer experiences 
                                    and reduced costs are the most effective in finding and funding more diverse business opportunities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="card">
                    <div className="card-body margin-top-0px">
                        <div className="card-content w-100p">
                            <div className="card-content-para">
                                <p>
                                    AI is definitely the future in financial services. Companies that want to thrive need to incorporate it as soon as possible.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section>
            <div className="box-container"> 
                <div className="card justify-content-center">
                    <div className="card-body margin-BT-10px">
                        <div className="card-content w-100p">
                            <div className="card-content-para">
                                <p>
                                    Please feel free to contact us.
                                </p>
                                <div className="btn-wrapper-no-pad">
                                    <ContactBtn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    </div>
  )
}
